import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { AI_SERVICE_URL, CORE_PLATFORM_URL, OSTEOCHECK_MOD_STUDY } from '../utils/constants'
import Reporting from './Reporting'
import Osteocheck from './Osteocheck'
import { Dna } from 'react-loader-spinner'
import styled from 'styled-components'
import { useAppSelector } from '../redux/utils/hooks'

export interface studyData {
  created_at: Date
  ct_ratio: any
  history: string
  mod_study: number
  order_id: string
  patient_name: string
  report_header: string
  status: string
  study_id: number
  study_iuid: string
}

const Cases = () => {
  const [loading, setLoading] = useState(true)
  const [studyData, setStudyData] = useState<studyData>()
  const [viewer, setviewer] = useState<any>([])
  const { id } = useParams()
  const navigate = useNavigate()
  const user = useAppSelector(state => state.auth.user)
  const userId = user && Number(user.id)

  const fetchCaseDetails = () => {
    fetch(`${AI_SERVICE_URL}/study/${id}?by_user_fk=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data?.error) {
          navigate('/error')
        } else {
          setStudyData(data?.data?.[0] ?? {})
        }
        setLoading(false)
      })
      .catch(error => {
        navigate('/error')
        console.error('Error:', error)
      })
  }

  useEffect(() => {
    if (userId) {
      fetchCaseDetails()
    }
  }, [])

  const dicomcall = async () => {
    try {
      const iuid = studyData?.study_iuid
      const response2 = await fetch(`${CORE_PLATFORM_URL}/dicom/viewer-token?study_iuid=${iuid}`)
      const token = await response2.text()
      const dicomviewer = `https://dcm.5cnetwork.com/?token=${token}`
      setviewer(dicomviewer)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    dicomcall()
  }, [studyData])

  if (loading) {
    return (
      <LoaderWrapper>
        <Dna
          visible={true}
          height='150'
          width='150'
          ariaLabel='dna-loading'
          wrapperStyle={{}}
          wrapperClass='dna-wrapper'
        />
      </LoaderWrapper>
    )
  }

  return (
    <div>
      {studyData?.mod_study === OSTEOCHECK_MOD_STUDY ? (
        <Osteocheck
          studyData={studyData}
          viewer={viewer}
        />
      ) : (
        <Reporting
          studyData={studyData!}
          viewer={viewer}
        />
      )}
    </div>
  )
}

export default Cases

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

import React, { useEffect, useState } from 'react';
import {  Navigate, Outlet } from 'react-router-dom';
import { Dna } from 'react-loader-spinner';
import { useAppSelector } from '../redux/utils/hooks';
import {  isInFlightSuccess } from '../utils/helper';
import { InFlightNames } from '../utils/types';

const ProtectedRoutes: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const {inFlights,isAuthenticated} = useAppSelector(state=> state.auth)
  useEffect(() => {
    if (isInFlightSuccess(inFlights, InFlightNames.requestUserInfoInFlight)) {
      setLoading(false)
    }
  }, []) 
  if (loading) {
    return (
      <div className="loader-effects">
        <Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperClass="dna-wrapper" />
      </div>
    );
  }

 if(isAuthenticated){
  return <Outlet/>
 }
 return(
  <Navigate
      to={'/'}
      replace
    />
 )
};

export default ProtectedRoutes;
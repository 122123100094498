import React from 'react'
import { Col, Descriptions, Row, Tag, Typography } from 'antd'
import styled from 'styled-components'
import Icon from '../shared/Icon'

type PatientDetailsProps = {
  patientDetails: {
    order_id: string
    patient_name: string
    report_header: string
    history: string
    paediatric: boolean
    status: string
    is_critical: string
    other_details: any
    is_ai_report: boolean
    is_demo: boolean
  }
}
const { Text } = Typography

const PatientDetails = (props: PatientDetailsProps) => {
  const { patientDetails } = props
  const { order_id, patient_name, is_demo, is_ai_report, report_header, history, is_critical, status, other_details } =
    patientDetails

  const patientDescriptionDetails = [
    {
      key: '1',
      label: <Text strong>Order ID</Text>,
      children: <Text type='secondary'>{order_id}</Text>,
    },
    {
      key: '2',
      span: 2,
      label: <Text strong>Study & Modality</Text>,
      children: <Text type='secondary'>{report_header}</Text>,
    },
    {
      key: '3',

      label: <Text strong>Age</Text>,
      children: <Text type='secondary'>{other_details?.osteocheck_patient_details?.patient_age ?? '-'}</Text>,
    },
    {
      key: '4',
      span: 12,
      label: <Text strong>BMI</Text>,
      children: <Text type='secondary'>{other_details?.osteocheck_patient_details?.patient_bmi ?? '-'}</Text>,
    },
    {
      key: '5',
      span: 12,
      label: <Text strong>History</Text>,
      children: <Text type='secondary'>{history}</Text>,
    },
  ]

  return (
    <PatientDetailsContainer>
      <Descriptions
        title={
          <Row gutter={[10, 10]}>
            <Col>{patient_name}</Col>
            <Col>
              <Tag color='green'>{status}</Tag>
            </Col>

            {is_demo && (
              <Col>
                <Icon
                  name='demo'
                  width={30}
                  height={30}
                />
              </Col>
            )}

            {is_critical && (
              <Col>
                {
                  <Icon
                    name='critical'
                    width={30}
                    height={30}
                  />
                }
              </Col>
            )}

            {is_ai_report && (
              <Col>
                {
                  <Icon
                    name='bionic'
                    width={30}
                    height={30}
                  />
                }
              </Col>
            )}
          </Row>
        }
        items={patientDescriptionDetails}
      />
    </PatientDetailsContainer>
  )
}

export default PatientDetails

const PatientDetailsContainer = styled.div`
  margin: 0.5rem;
`

import React, { useState, useRef } from 'react'
import prodigi from '../Assets/prodigi-trans.png'
import { UserCredential, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../Auth/firebaseAuth'
import { Toast } from 'primereact/toast'
import { Navigate, useNavigate } from 'react-router-dom'
import { AI_SERVICE_URL } from '../utils/constants'
import axios from 'axios'
import styled from 'styled-components'
import { Button as AntButton, Input } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { theme } from '../components/shared/theme'
import {   useAppSelector } from '../redux/utils/hooks'

export default function Login() {  
  const navigate = useNavigate()
  const toast = useRef(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { isAuthenticated } = useAppSelector(state=> state.auth)
  if(isAuthenticated) {
    return <Navigate to ='/AiFlow'  />
  }
  const showError = () => {
    if (toast.current) {
      ;(toast.current as any).show({
        severity: 'error',
        summary: 'Error',
        detail: 'Incorrect username or password',
        life: 4000,
      })
    }
  }

 

  const handleLogin = async (email: string, status: string, userCredential: UserCredential) => {
    try {
      const apiStatus = status
      const payload = {
        result: {
          email: email,
          status: apiStatus,
        },
      }
      setIsLoading(true)
      await axios.post(`${AI_SERVICE_URL}/user/on-off`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const broadcastChannel = new BroadcastChannel('user-presence-status-channel')

      broadcastChannel?.postMessage({
        presence_status: 'ACTIVE',
      })
      broadcastChannel?.close()
      setIsLoading(false)
      navigate('/AiFlow')
    } catch (error) {
      setIsLoading(false)
      console.error('Error in EnableApiCall:', error)
      throw error
    }
  }

  const signIn = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        handleLogin(email, 'ENABLE', userCredential)
      })
      .catch(error => {
        showError()
        console.log(error)
      })
  }

  return (
    <form>
      <div className='card h-screen surface-300'>
        <div className='flex flex-column justify-content-center h-screen  md:flex-row'>
          <div className='w-full md:w-3 flex flex-column align-items-center justify-content-center gap-3 py-5 login-container'>
            <img
              src={prodigi}
              className='prodigi-img'
            ></img>
            {/* <label className='breaker'>AI-Flow</label> */}
            <div className='flex flex-wrap justify-content-center align-items-center gap-2'>
              <label className='w-4rem'>
                <i
                  className='pi pi-user'
                  style={{ fontSize: '2rem' }}
                ></i>
              </label>
              <StyledInput
                id='username'
                type='email'
                placeholder='Email'
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className='flex flex-wrap justify-content-center align-items-center gap-2'>
              <label className='w-4rem'>
                <img
                  width='35'
                  height='35'
                  src='https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/64/external-password-cycber-security-system-inipagistudio-mixed-inipagistudio.png'
                  alt='external-password-cycber-security-system-inipagistudio-mixed-inipagistudio'
                />
              </label>
              <StyledInput
                id='password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder='password'
                required
              />
            </div>

            <LoginButton
              onClick={signIn}
              ref={Toast as any}
              loading={isLoading}
              icon={<UserOutlined style={{ fontSize: '1.2rem', color: 'white' }} />}
            >
              Login
            </LoginButton>
            <Toast ref={toast} />
          </div>
        </div>
      </div>
    </form>
  )
}

const StyledInput = styled(Input)`
  width: 12rem;
  padding: 0.7rem 0.5rem;
  border-color: ${theme.colors.appPrimary} !important;
`

const LoginButton = styled(AntButton)`
  background-color: ${theme.colors.appPrimary} !important;
  color: ${theme.colors.appBackground} !important;
  border-width: 0 !important;
  font-size: 1.3rem;
  padding: 1.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2.2rem;
  width: 10rem;
`

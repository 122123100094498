import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AiFlow from './pages/Homepage'
import Login from './pages/Login'
import PageNotFound from './pages/PageNotFound'
import Error from './pages/Error'
import { ConfigProvider, Spin } from 'antd'
import  styled, { ThemeProvider } from 'styled-components'
import { appTheme } from './style/configTheme'
import { theme } from './components/shared/theme'
import Cases from './pages/Cases'
import Layout from './components/shared/Layout'
import ReworkStudies from './pages/reworks'
import Settings from './pages/settings'
import { TooltipProvider } from '../src/components/plate-ui/tooltip'
import { cn } from '@udecode/cn'
import ProtectedRoutes from './components/ProtectedRoute';
import { auth } from './Auth/firebaseAuth'
import { useAppDispatch, useAppSelector } from './redux/utils/hooks'
import { requestUserInfo } from './redux/auth/authSlice'

function App() {


  const {isAuthenticated} = useAppSelector(state =>state.auth)
  const dispatch = useAppDispatch();
  useEffect(() => {
    const channel = new BroadcastChannel('user-presence-status-channel');
    channel.addEventListener('message', (e: any) => {
      if (e.data.presence_status === 'INACTIVE') {
        window.location.href = '/';
      }
    });

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
        await dispatch(requestUserInfo({ email: user?.email }));
    });
    return () => {
      unsubscribe();
    };
  }, []);
  
  if(isAuthenticated === null){
    return(
      <CenteredContainer>
      <Spin />
    </CenteredContainer>
    )
  }
  return (
    <ConfigProvider theme={appTheme}>
      <ThemeProvider theme={theme}>
        <TooltipProvider
          disableHoverableContent
          delayDuration={500}
          skipDelayDuration={0}
        >
          <div
            className={cn(
              'min-h-screen bg-background font-sans antialiased',
              '[&_.slate-selected]:!bg-primary/20 [&_.slate-selection-area]:border [&_.slate-selection-area]:border-primary [&_.slate-selection-area]:bg-primary/10'
            )}
            suppressHydrationWarning
          >
            <Router>
              <Routes>
                <Route path='/' element={<Login />} />
                <Route element={<ProtectedRoutes />}>
                  <Route
                    path='/AiFlow'
                    element={
                      <Layout>
                        <AiFlow />
                      </Layout>
                    }
                  />
                  <Route
                    path='/rework'
                    element={
                      <Layout>
                        <ReworkStudies />
                      </Layout>
                    }
                  />
                  <Route
                    path='/settings'
                    element={
                      <Layout>
                        <Settings />
                      </Layout>
                    }
                  />
                  <Route path='/Cases/:id' element={<Cases />} />
                  <Route path='/error' element={<Error />} />
                </Route>
                <Route path='*' element={<PageNotFound />} />
              </Routes>
            </Router>
          </div>
        </TooltipProvider>
      </ThemeProvider>
    </ConfigProvider>
  )
}

export default App

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '../utils/actionTypes';
import { RequestLoginPayloadType, RequestUserStatusPayloadType } from './authPayloadTypes';
import { auth } from '../../Auth/firebaseAuth'
import { InFlightNames, InFlightStatuses } from '../../utils/types';
import AxiosInstance from '../AxiosInstance'
import { getAPIEndPoints } from '../../utils/api';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { message } from 'antd';

export enum UserStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export enum UserRoleTypes  {
    APP = 'APP',
    REPORT ='REPORT',
    DEMO = 'DEMO'
}
export type UserDetailsType = {
    id?:number,
    email: string,
    name?: string,
    status:UserStatus,
    type?:UserRoleTypes
}

export interface AuthState {
    user: UserDetailsType;
    isAuthenticated: null | boolean;
    inFlights: {
        [InFlightNames.requestLoginInFlight]: { status: InFlightStatuses },
        [InFlightNames.requestUserInfoInFlight]: { status: InFlightStatuses }
        [InFlightNames.requestUserStatusInFlight]: { status: InFlightStatuses },
        [InFlightNames.requestUpdateUserStatusInFlight]: { status: InFlightStatuses }
    }
}

const initialState: AuthState = {
    user: {
        email:'',
        name:'',
        status:UserStatus.DISABLED,
        type:UserRoleTypes.REPORT
    } as UserDetailsType,
    isAuthenticated: null,
    inFlights: {
        [InFlightNames.requestLoginInFlight]: { status: InFlightStatuses.INITIAL },
        [InFlightNames.requestUserInfoInFlight]: { status: InFlightStatuses.INITIAL },
        [InFlightNames.requestUserStatusInFlight]: { status: InFlightStatuses.INITIAL },
        [InFlightNames.requestUpdateUserStatusInFlight]: { status: InFlightStatuses.INITIAL },
    }
}


export const requestLogin = createAsyncThunk<any, RequestLoginPayloadType>(
    Actions.requestLogin,
    async (data, { rejectWithValue, dispatch }) => {
        const { email, password } = data;
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            await dispatch(requestUserInfo({email}))
            // await dispatch(requestUpdateUserStatus({ email, status: UserStatus.ENABLED ,isAuthenticated:true}))
            message.success('Login Successfully')
            return userCredential.user;
        } catch (error) {
            message.error('Invalid Credientials')
            return rejectWithValue({})
        }
    }
);


export const requestUserInfo = createAsyncThunk<any,{email:string | null | undefined}>(
    Actions.requestUserInfo,
    async(data,{rejectWithValue})=>{
        const {email} = data        
        try{
            const response = {
                isAuthenticated: false,
                user: {}
            }
            if(email){
            const user = await AxiosInstance.get(getAPIEndPoints.auth.get_user_info(email),{
                headers: {
                    'Content-Type': 'application/json',
                  },
            })
           
            response.user  = user.data
            response.isAuthenticated = true
        }
        return response;
        }catch(e){
          return rejectWithValue({})
        }
    }
)

export const requestUpdateUserStatus = createAsyncThunk<any, RequestUserStatusPayloadType>(
    Actions.requestUpdateUserStatus,
    async (data , { rejectWithValue }) => {
        const {email,status,isAuthenticated,name} = data
        try {
            const payload = {
                result: {
                    email,
                    status
                }
            }
              await AxiosInstance.post(
                getAPIEndPoints.auth.user_status(),
                {
                    data: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return data;
        } catch (error) {
            console.error("Error:", error);
            return rejectWithValue({});
        }
    }
);


export const requestLogout = createAsyncThunk<void,any>(
    Actions.requestLogout,
    async (data,{ rejectWithValue ,dispatch})=>{
       
        const {userEmail} = data
        try{
            const logout = auth.signOut()
            await dispatch(requestUpdateUserStatus({ email: userEmail, status: UserStatus.DISABLED ,isAuthenticated:false}))
        }catch(e)
        {
            return rejectWithValue({})
        }
    }
)






const { reducer } = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            //

            .addCase(requestUpdateUserStatus.pending, state => {
                state.inFlights.requestUpdateUserStatusInFlight.status = InFlightStatuses.PENDING
            })
            .addCase(requestUpdateUserStatus.fulfilled, (state, action) => {
                state.inFlights.requestUpdateUserStatusInFlight.status = InFlightStatuses.SUCCESS  
                state.user.email = action.payload.email
                state.isAuthenticated = action.payload.isAuthenticated
                state.user.name = action.payload.displayName
            })
            .addCase(requestUpdateUserStatus.rejected , state=>{
                state.inFlights.requestUpdateUserStatusInFlight.status = InFlightStatuses.ERROR
            })


            // requestUserInfo ============================>

            .addCase(requestUserInfo.pending, state => {
                state.inFlights.requestUserInfoInFlight.status = InFlightStatuses.PENDING
            })
            .addCase(requestUserInfo.fulfilled, (state, action) => {
                state.inFlights.requestUserInfoInFlight.status = InFlightStatuses.SUCCESS  
                state.isAuthenticated = action.payload.isAuthenticated
                state.user ={
                    ...state.user,
                    ...action.payload.user
                    }
            })
            .addCase(requestUserInfo.rejected , state=>{
                state.isAuthenticated = false
                state.inFlights.requestUserInfoInFlight.status = InFlightStatuses.ERROR
            })
    }
})

export default reducer;
export enum Actions {
  //auth
  requestLogin = 'requestLogin',
  requestUpdateUserStatus = 'requestUpdateUserStatus',
  requestUpdateAuthStatus = 'requestUpdateAuthStatus',
  requestLogout = 'requestLogout',
  requestUserInfo='requestUserInfo',

  // cases
  requestNewCase = 'requestNewCase',
  requestCasesInPoolCount = 'requestCasesInPoolCount',
  requestCaseBasedOnDate = 'requestCaseBasedOnDate',
  requestSkipReasons = 'requestSkipReasons',

  // reports
  requestGetStudy = 'requestGetStudy',
  requestGetReportTemplate = 'requestGetReportTemplate',
  requestGetDicomUrlToken = 'requestGetDicomUrlToken',
  requestTakeAction = 'requestTakeAction',

  // settings
  requestUpdateSettings = 'requestUpdateSettings',
  requestGetSettings = 'requestGetSettings',
}

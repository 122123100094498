import React, { useEffect, useState } from 'react'

import { Button as AntButton, Layout as AntLayout, Avatar, Space } from 'antd'
import { AI_SERVICE_URL } from '../../utils/constants'
import { auth } from '../../Auth/firebaseAuth'
import { useNavigate } from 'react-router-dom'
import type { NotificationArgsProps } from 'antd'
import prodigi from '../../Assets/prodigi-trans.png'
import { notification, Dropdown as AntDropdown } from 'antd'
import axios from 'axios'
import {
  DownOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  LogoutOutlined,
} from '@ant-design/icons'
import { theme } from './theme'
import { useAppDispatch } from '../../redux/utils/hooks'


type NotificationPlacement = NotificationArgsProps['placement']
type NotificationType = 'success' | 'info' | 'warning' | 'error'

const { Header: AntHeader } = AntLayout

type Props = {
  children?: React.ReactNode
}

const Header = (props: Props) => {
  const [userDetails, setUserDetails] = useState<any>({ status: 'INACTIVE' })
  const [userEmail, setUserEmail] = useState<string | undefined>()
  const [isUserStatusLoading, setIsUserStatusLoading] = useState(false)
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  const dispatch  = useAppDispatch()
  const avatarLabel = userEmail ? userEmail.charAt(0).toUpperCase() : 'U'

  const openNotificationWithIcon = (
    type: NotificationType,
    message = '',
    description = '',
    placement: NotificationPlacement
  ) => {
    api[type]({
      message,
      description,
      placement,
      duration: 0,
    })
  }

  useEffect(() => {
    const user = auth.currentUser
    if (user) {
      setUserEmail(user.email!)
    } else {
      setUserEmail('')
    }

    const channel = new BroadcastChannel('user-presence-status-channel')
    channel.addEventListener('message', (e: any) => {
      if (e.data.presence_status) {
        setUserDetails((prevState: any) => ({
          ...prevState,
          status: e.data.presence_status,
        }))
      }
    })

    return () => {
      channel.removeEventListener('message', () => {})
      channel.close()
    }
  }, [])

  const handleUserPresenceStatus = async (status = 'DISABLE') => {
    const payload = {
      result: {
        email: userEmail,
        status,
      },
    }
    setIsUserStatusLoading(true)

    try {
      await axios.post(`${AI_SERVICE_URL}/user/on-off`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const broadcastChannel = new BroadcastChannel('user-presence-status-channel')
      broadcastChannel?.postMessage({
        presence_status: status === 'DISABLE' ? 'INACTIVE' : status === 'AWAY' ? 'AWAY' : 'ACTIVE',
      })
      broadcastChannel?.close()

      setIsUserStatusLoading(false)
      if (status == 'DISABLE') {
        auth.signOut()
        navigate('/')
      }
    } catch (error) {
      setIsUserStatusLoading(false)

      if (error.response) {
        openNotificationWithIcon('warning', 'Refresh', error.response.data.message ?? 'Something wernt wrong', 'top')
      } else {
        openNotificationWithIcon('error', 'Error', 'Something went wrong', 'top')
      }
    }
  }

  useEffect(() => {
    if (userEmail) {
      fetch(`${AI_SERVICE_URL}/user/?email=${userEmail}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            openNotificationWithIcon('error', 'Error', 'Something went wrong!', 'top')
          }
          return response.json()
        })
        .then(data => {
          if (data?.user_details?.status == 'INACTIVE') {
            navigate('/')
          } else if (data?.user_details) {
            setUserDetails(data?.user_details)
          }
        })
        .catch(error => {
          openNotificationWithIcon('error', 'Error', error?.message ?? '', 'top')
        })
    }
  }, [userEmail])
  return (
    <AntHeader
      style={{
        display: 'flex',
        alignItems: 'center',
        zIndex: 10,
        top: 0,
        padding: '0 1rem 0 0.5rem',
        height: '8vh',
        position: 'fixed',
        width: '100vw',
      }}
    >
      <div className='navbar'>
        {contextHolder}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <img
            src={prodigi}
            className='homepage-navbar'
          ></img>

          <div style={{ display: 'flex', alignItems: 'center', padding: '0 2rem' }}>
            <Avatar size='large'>{avatarLabel}</Avatar>
            {userDetails?.type == 'REPORT' ? (
              <AntDropdown
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <a
                          target='_blank'
                          onClick={() => (userDetails?.status !== 'ACTIVE' ? handleUserPresenceStatus('ENABLE') : {})}
                        >
                          <CheckCircleTwoTone twoToneColor={theme.colors.success} /> Active
                        </a>
                      ),
                    },
                    {
                      key: '2',
                      label: (
                        <a
                          target='_blank'
                          onClick={() => (userDetails?.status !== 'AWAY' ? handleUserPresenceStatus('AWAY') : {})}
                        >
                          <ExclamationCircleTwoTone twoToneColor={theme.colors.warning} /> Away
                        </a>
                      ),
                    },
                    {
                      key: '3',
                      label: (
                        <a
                          target='_blank'
                          onClick={() =>
                            userDetails?.status !== 'INACTIVE' ? handleUserPresenceStatus('DISABLE') : {}
                          }
                        >
                          <CloseCircleTwoTone twoToneColor={theme.colors.danger} /> Logout
                        </a>
                      ),
                    },
                  ],
                }}
                placement='bottom'
              >
                <AntButton
                  type='text'
                  loading={isUserStatusLoading}
                >
                  <Space>
                    <b>
                      {userDetails?.status
                        ? userDetails?.status?.charAt(0)?.toUpperCase() + userDetails?.status?.slice(1)?.toLowerCase()
                        : 'Inactive'}
                    </b>
                    <DownOutlined />
                  </Space>
                </AntButton>
              </AntDropdown>
            ) : (
              <AntButton
                size='large'
                type='text'
                loading={isUserStatusLoading}
                onClick={() => handleUserPresenceStatus('DISABLE')}
                style={{ display: 'flex' }}
              >
                Logout
                <LogoutOutlined style={{ fontSize: '1.4rem' }} />
              </AntButton>
            )}
          </div>
        </div>
      </div>
    </AntHeader>
  )
}

export default Header

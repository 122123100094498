import React, { useEffect } from 'react'
import { Button, Flex, Modal, Radio, Form as AntForm, Spin } from 'antd'
import { Form, Formik } from 'formik'
import { Input } from 'antd'
import { theme } from '../shared/theme'
import * as Yup from 'yup'
import { ReasonTypes, requestSkipReasons } from '../../../src/redux/cases/caseSlice'
import { OTHERS_SKIP_REASON } from '../../../src/utils/constants'
import { useAppDispatch, useAppSelector } from '../../../src/redux/utils/hooks'
import { InFlightNames } from '../../utils/types'
import { isInFlightPending } from '../../../src/utils/helper'

const { TextArea } = Input

type ReworkActionModalProps = {
  is_open: boolean
  onSubmit: (reason_id: number, comment: string) => Promise<void>
  onCancel: () => void
}

const ReworkActionModal = (props: ReworkActionModalProps) => {
  const { is_open, onSubmit, onCancel } = props

  const dispatch = useAppDispatch()
  const { inFlights, skipReasons } = useAppSelector(state => state.cases)

  useEffect(() => {
    if (is_open && skipReasons.length === 0) {
      dispatch(requestSkipReasons({ reasonType: ReasonTypes.SKIP }))
    }
  }, [is_open])

  const validationSchema = Yup.object().shape({
    reason_id: Yup.number().required('Please select a reason'),
    comment: Yup.string().when('reason_id', {
      is: OTHERS_SKIP_REASON,
      then: Yup.string().required('Comment is required when selecting Others'),
      otherwise: Yup.string().optional(),
    }),
  })

  return (
    <Modal
      open={is_open}
      title='Skip Reason'
      footer={[]}
      onCancel={onCancel}
    >
      {isInFlightPending(inFlights, InFlightNames.requestSkipReasonInFlight) ? (
        <Flex
          justify='center'
          align='center'
          style={{ height: '20rem' }}
        >
          <Spin size='large' />
        </Flex>
      ) : (
        <Formik
          initialValues={{
            reason_id: 0,
            comment: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async values => {
            await onSubmit(values.reason_id, values.comment)
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form style={{ margin: '1rem' }}>
              <Flex
                gap='middle'
                vertical
              >
                <AntForm.Item
                  validateStatus={errors.reason_id && touched.reason_id ? 'error' : ''}
                  help={errors.reason_id && touched.reason_id ? errors.reason_id : null}
                >
                  <Radio.Group
                    value={values.reason_id}
                    onChange={e => setFieldValue('reason_id', e.target.value)}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      gap: '1rem',
                    }}
                  >
                    {skipReasons.map(reason => (
                      <Radio
                        key={reason.id}
                        value={reason.id}
                      >
                        {reason.reason}
                      </Radio>
                    ))}
                  </Radio.Group>
                </AntForm.Item>

                <AntForm.Item
                  validateStatus={errors.comment && touched.comment ? 'error' : ''}
                  help={errors.comment && touched.comment ? errors.comment : null}
                >
                  <TextArea
                    value={values.comment}
                    onChange={e => setFieldValue('comment', e.target.value)}
                    placeholder={values.reason_id === 27 ? 'Comment (required)' : 'Comment (optional)'}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                  />
                </AntForm.Item>
              </Flex>
              <Flex
                gap='middle'
                style={{ marginTop: '1rem' }}
                justify='end'
              >
                <Button
                  key='cancel'
                  type='default'
                  danger
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                <Button
                  key='submit'
                  type='primary'
                  htmlType='submit'
                  style={{ backgroundColor: theme.colors.appPrimary, color: theme.colors.appBackground }}
                >
                  Submit
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default ReworkActionModal
